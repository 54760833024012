import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

// REMOVE CLASS FROM items ARRAY
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
      inventory:null,
  },
  plugins: [createPersistedState()],
  getters: {
    inventory(state){  // ingredients
      return state.inventory;
    }
  },
  mutations: {
    setInventory(state, n) {
      this.state.inventory = n;
    },
    addInventory(state, n) {
      this.state.inventory.push(n)
    }
  }
})