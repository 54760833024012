<template>
  <div class="container-flex">
    <nav class="navbar navbar-light">
      
      <div class="dropdown d-xl-none d-lg-none mr-auto">
      <i class="fas fa-list fa-lg" data-toggle="dropdown" data-target="#navd" aria-haspopup="true" aria-expanded="false"></i>
        
        <div class="dropdown-menu hb" aria-labelledby="navd">
          <router-link class="dropdown-item" to="/">Home</router-link>
            
            <router-link v-if="(userInfo && (userInfo.subscription_status == 'in_trial' || userInfo.subscription_status == 'active' || userInfo.subscription_status == 'paused'))" class="dropdown-item" to="/manage-account">
              Manage Account
            </router-link>
            <router-link v-if="(userInfo && userInfo.subscription_status == 'not_subscribed')" class="dropdown-item" :to="{name : 'Pricing',params: { userInfo: userInfo }}">
              Subscribe
              </router-link>        
            <router-link v-if="(userInfo && (userInfo.subscription_status == 'in_trial' || userInfo.subscription_status == 'active'))" class="dropdown-item" to="/recipes">
              Recipes
            </router-link>
            <router-link v-if="(userInfo && (userInfo.subscription_status == 'in_trial' || userInfo.subscription_status == 'active'))" class="dropdown-item" to="/meals">
              Meals
            </router-link>
            <router-link v-if="(userInfo && (userInfo.subscription_status == 'in_trial' || userInfo.subscription_status == 'active'))" class="dropdown-item" to="/ingredientInventory">
              Inventory
            </router-link>
             
            <router-link v-else class="dropdown-item" :to="{name : 'Pricing',params: { userInfo: userInfo }}">
              Pricing
            </router-link>
          <router-link class="dropdown-item" to="/about-us">About us</router-link>
          <router-link class="dropdown-item" to="/contact">Contact us</router-link>
          
          <!--<router-link class="dropdown-item" to="/help">Help</router-link>-->
        </div>
      </div>
      <!--Logo-->
      <a class="navbar-brand py-0 pl-5">
          <img src="@/assets/dishkraft.svg" width="150" height="50">
      </a>
      <!--Header navigation-->
      <span class="navbar-item bc d-none d-xl-block d-lg-block py-0">
        <router-link class="px-2" to="/">Home</router-link>
        
        <router-link v-if="(userInfo && (userInfo.subscription_status == 'in_trial' || userInfo.subscription_status == 'active' || userInfo.subscription_status == 'paused'))" class="px-2" to="/manage-account">
          Manage Account
        </router-link>
        <router-link v-if="(userInfo && userInfo.subscription_status == 'not_subscribed')" class="px-2" :to="{name : 'Pricing',params: { userInfo: userInfo }}">
          Subscribe
        </router-link>
        <router-link v-if="(userInfo && (userInfo.subscription_status == 'in_trial' || userInfo.subscription_status == 'active'))" class="px-2" to="/recipes">
          Recipes
        </router-link>
        <router-link v-if="(userInfo && (userInfo.subscription_status == 'in_trial' || userInfo.subscription_status == 'active'))" class="px-2" to="/meals">
          Meals
        </router-link>
        <router-link v-if="(userInfo && (userInfo.subscription_status == 'in_trial' || userInfo.subscription_status == 'active'))" class="px-2" to="/ingredientInventory">
          Inventory
        </router-link>

        <router-link  v-else class="px-2" :to="{name : 'Pricing',params: { userInfo: userInfo }}">
          Pricing
        </router-link>
        
        <router-link class="px-2" to="/about-us">About us</router-link>
        <router-link class="px-2" to="/contact">Contact us</router-link>
        
        <!--<router-link class="px-2" to="/help">Help</router-link>-->
      </span>

      <p class="navbar-item ml-auto">
        <!--
        <div class="searc d-none d-xl-block d-lg-block pr-3">
          <input type="search" class="search">
        </div>
        -->

        <div v-if="(userInfo!=null && userInfo.user_name != null)" class="welcome">
          Welcome {{userInfo.user_name}}
        </div>



        <div v-if="(subStatus == 'in_trial')" class="trial">
          Trial
        </div>
        
        <div class="user">
          <a v-if="(userInfo!=null)"  href="/app/logout.html" style="cursor: pointer">Sign Out</a>
          <a v-else href="/app/login.html" style="cursor: pointer">Sign In</a>
        </div>
        
    </nav>
    
    </div>
</template>
<script>
import '@fortawesome/fontawesome-free/css/all.css'; // Import the Font Awesome CSS
import eventBus from '../eventBus';
export default {
  name: 'DishkraftHeader',
  components: {
  },
   data() {
    return {
       userInfo:null,
       subStatus : null
    };
   },
  props: {
    //subStatus: String,
    //userInfo:{}
  },
  created() {
    console.log("header created  : "+JSON.stringify(this.userInfo));
    console.log("Cookie Banner : Necessar : "+this.$store.state.acceptedNecessaryCookies+" All : "+this.$store.state.acceptedAllCookies);
    if(this.$store.state.acceptedNecessaryCookies == null && this.$store.state.acceptedAllCookies == null) {
      eventBus.$emit("show-cookie-banner",true);
      console.log("showBanner : true");
    } else {
       console.log("showBanner : false");
       eventBus.$emit("show-cookie-banner",false);
    }
    
    this.getUserDetails();
    
    
    
  },
  computed: {
  },
  methods: {
    showMessage(message) {
      console.log("message : "+message);
      this.message = message;
    },
    closeMessage() {
      this.message = '';
    },
    async signOut() {
      console.log("signout");
      /*
      try {
        this.loading = true;
        const response = await fetch(window.location.origin+'/user-details');

        console.log("userInfo :  "+response.status);
         if(response.status == 200) {
          this.$store.commit('clearAll');
        }
       
      } catch (error) {
        console.log(error);
        this.alertMessage = 'System Error';
        this.alertColor = 'alert-danger';
        this.loading=true;
      }
      this.$router.push({ name: 'IndexHome' });
      */
    },
    async getUserDetails() {
      console.log("getUserDetails");
      try {
        eventBus.$emit("show-message","info", "Loading...");
        const response = await fetch(window.location.origin+'/user-details');

        console.log("userInfo :  "+response.status);
        if(response.status == 401) {
          this.user = false;
          this.$store.commit('clearAll');
         
        } else if(response.status == 200) {
          this.user = true;
          const responseData = await response.json();
          this.subStatus = responseData.subscription_status;
          this.userInfo = responseData;
          console.log("response data : "+JSON.stringify(responseData));
          if(this.userInfo != null && (this.userInfo.subscription_status == 'not_subscribed' || this.userInfo.subscription_status == 'canceled')) {
            if(this.userInfo.newsubscription_url) {
              window.location.href = this.userInfo.newsubscription_url;
            }
          } else {
            this.$store.commit('setUser', responseData);
          }
          
        }
        eventBus.$emit("close-message");

       
      } catch (error) {
        console.log(error);
        eventBus.$emit("show-message","danger", "Error loading page");
      }
    }
  }
}

</script>

<style>
nav {
  z-index:100;
}

.navbar {
  border-bottom: 1px solid #DCDCDC;
  background-color: #f8f8f8;
}

.close {
  position:relative;
  bottom:20px;
  left:10px;
  font-size: 31px;
  color: #000;
}

.navbar-item.bc a {
  font-size: 17px;
  text-decoration: none;
  color: black;
}

.navbar-item.bc a:hover, .navbar-item.bc a:active {
  color: #FFD700;
}
.btn-sm{
  border-radius: 0;
}

.search {
  outline: none;
  border: 1px #F8F8F8;
  background: #ededed url('../assets/search.png') no-repeat 5px center;
  padding: 5px 8px 0px 26px;
  width: 10px;
  -webkit-border-radius: 10em;
  -moz-border-radius: 10em;
  border-radius: 10em;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  transition: all .5s;
  margin-right: 10px;
}

.search:focus {
  width: 160px;
  border: solid 1px #ccc;
  background-color: #fff;
  border-color: #98ccfd;
  -webkit-box-shadow: 0 0 5px rgba(109, 207, 246, .5);
  -moz-box-shadow: 0 0 5px rgba(109, 207, 246, .5);
  box-shadow: 0 0 5px rgba(109, 207, 246, .5);
  backface-visibility: hidden;
  perspective: 1000;
}

form .btn-xl.btn-success.mt-3 {
  position: relative;
  -webkit-transition-duration: 100ms;
  transition-duration: 100ms;
  width: 100%;
  height: 50px;
  font-size: 20px;
  outline:none;
  cursor: pointer;
  box-shadow: 0 26px 38px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.bag span {
    background-color: #6394F8;
    border-radius: 10px;
    color: white;
    position: absolute;
    font-size: 15px;
    line-height: 1;
    padding: 2px 3px 3px 3px;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    margin-left: -9px;
    bottom: 1rem;
}

.bag img {
  cursor: pointer;
  width: 30px;
  height: auto;
}

.user{
  margin-right: 20px;
  padding-top: 10px;
}


.user:hover {
  text-decoration: underline;
}

.trial{
  margin-right: 20px;
  padding-top: 10px;
  color: red;
}

.welcome{
  margin-right: 20px;
  padding-top: 10px;
  color: green;
}

</style>
