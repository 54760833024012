import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

// REMOVE CLASS FROM items ARRAY
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    acceptedNecessaryCookies : null,
    acceptedAllCookies : null,
    declinedCookies : null,
    recipesUpdated : false,
    infoPage:[],
    recipeSteps:{
      name : '',
      description:'',
      ingredients : [],
      preparations : []
    },
    user : null,
    items: [],
    meals : [],
    mealRecipes : [],
    shoppingList : [],
    lastRoute : "/",
    recipeSearchForm : null,
    mealSearchFormData : null,
    currentEditRecipe : null,
    recipeFormData : null,
    inventory:null,
  },
  plugins: [createPersistedState()],
  getters: {
    ingredients(state){  // ingredients
      return state.recipeSteps.ingredients;
    },
    previewRecipeSteps(state){  // Cart Component
      return state.recipeSteps;
    },
    viewRecipeSteps(state){  // Cart Component
      return state.recipeSteps;
    },
    infoLength(state) { // Info Component
      if(state.infoPage.length > 0) {
        return state.infoPage.splice(0, 1)
      }
    },
    user(state) { 
      return state.user;
    },
    inventory(state){  // ingredients
      return state.inventory;
    }
  },
  mutations: {
    inCart(state, n) { // Cart Component
      return state.cartItems.push(n)
    },
    outCart(state, n) { // Cart Component
      let index = state.cartItems.findIndex(x => x.id === n)
      return state.cartItems.splice(index, 1)
    },
    addtoInfo(state, n) { // Info Component
       return state.infoPage.push(n)
    },
    addIngredient(state, n) { // recipe
      console.log("Add Ingredients : "+state.recipeSteps);
       return JSON.parse(state.recipeSteps).ingredients.push(n)
    },
    removeIngredient(state, n) { // recipe
      const recipesStepsState = JSON.parse(state.recipeSteps);
      console.log("remove recipeSteps : "+JSON.stringify(recipesStepsState));
      console.log("Remove Ingredients : "+JSON.stringify(recipesStepsState.ingredients));
      return recipesStepsState.ingredients.splice(n,1);
    },
    addPreparation(state, n) { // recipe
      const recipesStepsState = JSON.parse(state.recipeSteps);
      console.log("Add Preparation : "+JSON.stringify(recipesStepsState.preparations));
      return recipesStepsState.preparations.push(n)
    },
    removePreparation(state, n) { // recipe
      const recipesStepsState = JSON.parse(state.recipeSteps);
      console.log("Remove Preparation : "+JSON.stringify(recipesStepsState.preparations));
      return recipesStepsState.preparations.splice(n,1)
    },
    addRecipeSteps(state, steps) {
      state.recipeSteps = steps;
    },
    addItems(state, n) {
      state.items.push(n)
   },
   setRecipesUpdated(state, updated) {
     state.recipesUpdated = updated;
  },
   setItems(state, n) {
     return this.state.items = n;
   },setItems(state, n) {
    return this.state.items = n;
  },
   setMeals(state, n) {
     return this.state.meals = n;
   },
   setMealRecipes(state, n) {
    return this.state.mealRecipes = n;
  },setShoppingList(state, n) {
    return this.state.shoppingList = n;
  },
   setUser(state, n) { 
     return state.user = n;
   },
   clearAll() { 
     this.state.recipeSteps = {};
     this.state.user = null;
     this.state.items = null;
     this.state.meals = null;
     this.state.mealRecipes = null;
     this.state.shoppingList = null;
   },
   setAllCookiesAccepted(state, status) { 
      state.acceptedAllCookies = status;
    },
    setNecessaryCookiesAccepted(state, status) {
       state.acceptedNecessaryCookies = status;
     },
    setCookiesDeclined(state) {
       state.declinedCookies = true;
     },
     removeItemAtIndex(state, index) {
      //'index' is the index of the item you want to remove
      state.items.splice(index, 1);
    },
    setLastRoute(state, n) { 
      return state.lastRoute = n;
    },
    setRecipeSearchForm(state, n) { 
      return state.recipeSearchForm = n;
    },
    setMealSearchFormData(state, n) { 
      return state.mealSearchFormData = n;
    },
    setCurrentEditRecipe(state, n) { 
      return state.currentEditRecipe = n;
    },
    setRecipeFormData(state, n) { 
      return state.recipeFormData = n;
    },
    setInventory(state, n) {
      this.state.inventory = n;
    },
    addInventory(state, n) {
      this.state.inventory.push(n)
    }
  
  },
})
