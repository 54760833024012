import Vue from 'vue'
//import { createApp } from "vue";
import App from './App.vue'
import router from './router'
import store from './store';
import inventoryStore from './stores/inventory-store';
import eventBus from './eventBus';
import './registerServiceWorker';

Vue.config.productionTip = false
Vue.config.devtools = true

new Vue({
  router, store, eventBus,inventoryStore,
  render: h => h(App)
}).$mount('#app');

/*
const appInstance = createApp(App);
appInstance.use(router);
appInstance.use(store);
appInstance.use(eventBus);
appInstance.mount("#app");
*/