<!-- DisableOverlay.vue -->
<template>
  <div v-if="isActive" class="disable-overlay"></div>
</template>

<script>
export default {
  props: {
    isActive: Boolean,
  },
};
</script>

<style scoped>
.disable-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 800%;
  background: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  z-index: 99; /* place it on top of everything */
}
</style>