import Vue from 'vue'
import VueRouter from 'vue-router';


const IndexHome = () => import('@/views/home/IndexHome.vue');
const Recipes = () => import('@/views/recipes/Recipes.vue');
const Contact = () => import('@/views/home/Contact.vue');
const AddRecipe = () => import('@/views/recipes/AddRecipe.vue');
const PreviewRecipe = () => import('@/views/recipes/PreviewRecipe.vue');
const Terms = () => import('@/views/home/Terms.vue');
const Privacy = () => import('@/views/home/Privacy.vue');
const Help = () => import('@/views/Help.vue');
const Pricing = () => import('@/views/home/PricingSt.vue');
const Feature = () => import('@/views/home/Feature.vue');
const ManageAccount = () => import('@/views/ManageAccount.vue');
const CookiePolicy = () => import('@/views/home/CookiePolicy.vue');
const AboutUs = () => import('@/views/home/AboutUs.vue');
const Meals = () => import('@/views/Meal/Meals.vue');
const ViewShoppingList = () => import('@/views/ShoppingList/ViewShoppingList.vue');
const IngredientInventory = () => import('@/views/Inventory/IngredientInventory.vue');

//const Invites = () => import('@/views/Invite/Invites.vue');
//const InviteForm = () => import('@/views/Invite/InviteForm.vue');


Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'IndexHome',
    component: IndexHome
  },
  {
    path: '/home',
    name: 'IndexHome',
    component: IndexHome
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/feature',
    name: 'Feature',
    component: Feature
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: Pricing,
    props: true
  },
  {
    path: '/help',
    name: 'Help',
    component: Help
  },
  {
    path: '/recipes',
    name: 'Recipes',
    component: Recipes,
    props: true
  },{
    path: '/addRecipe',
    name: 'AddRecipe',
    component: AddRecipe
  },{
    path: '/editRecipe',
    name: 'EditRecipe',
    component: AddRecipe,
    props: true
  },{
    path: '/previewRecipe',
    name: 'PreviewRecipe',
    component: PreviewRecipe,
    props: true
  },{
    path: '/viewRecipe',
    name: 'ViewRecipe',
    component: PreviewRecipe,
    props: true
  }/*,{
    path: '/invites',
    name: 'Invites',
    component: Invites
  },{
    path: '/invite',
    name: 'Invite',
    component: InviteForm
  }*/,
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/manage-account',
    name: 'ManageAccount',
    component: ManageAccount
  } ,
  {
    path: '/cookie-policy',
    name: 'CookiePolicy',
    component: CookiePolicy
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: AboutUs
  }
  ,
  {
    path: '/meals',
    name: 'Meals',
    component: Meals
  }
  ,
  {
    path: '/shoppingList',
    name: 'ViewShoppingList',
    component: ViewShoppingList,
    props: true
  },
  {
    path: '/ingredientInventory',
    name: 'IngredientInventory',
    component: IngredientInventory,
    props: true
  }
];

const router = new VueRouter({
  mode: 'hash',
  //base:'app/',
  routes,
});

router.beforeEach((to, from, next) => {
  if (from.name) {
    to.meta.previousRoute = { name: from.name };
  }
  next();
});

export default router;