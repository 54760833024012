
<template>
  <div id="app">
    <disable-overlay :isActive="isPageDisabled" />
    <header class="fixed-header">
      <Header />
    </header>
  <div class="message">
    <b-alert v-if="message" :variant="alertVariant" show fade >
      <div  class="row" role="alert" align="center">
        <div class="col10 col-xl-10 col-lg-10 col-md-10 col-sm-10" >
          <strong>{{message}}</strong>
        </div>
        <div class="col2 col-xl-2 col-lg-2 col-md-2 col-sm-2">
          <a href="#" class="close" @click="closeMessage" aria-label="close">&times;</a>
        </div>
      </div>
    </b-alert>
    </div>
    <router-view />
    <!--<Newsletter v-if="$route.path != '/contact'" />-->
   
   <!--
    <div class="cookie-banner">
    <b-alert v-if="showHideCookieBanner" :variant="alertVariant" show fade >
      <div v-if="!isUserSigned"  class="row" role="alert" align="center">
        <div class="col10 col-xl-10 col-lg-10 col-md-10 col-sm-10" >
          <strong>Cookie Policy</strong>
          <p>We use cookies to ensure you get the best experience on our website. By using our website, you agree to our use of cookies.
            <router-link  to="/cookie-policy">Cookie Policy</router-link>
          </p>
          
          <a href="#"  @click="acceptNecessaryCookies" class="btn btn-success">Accept Necessary</a>
          &nbsp;&nbsp;<a href="#"  @click="acceptAllCookies" class="btn btn-success">Accept All</a>
          &nbsp;&nbsp;<a href="#"   @click="declineCookies" class="btn btn-danger">Decline</a>
          
        </div>
      </div>
    </b-alert>
    </div>
    -->
    

    <div class="footer">
    <Footer />
    </div>
  </div>
</template>

<script>
import Header from '@/Components/Header.vue'
//import Newsletter from '@/Components/Newsletter.vue'
import Footer from '@/Components/Footer.vue'
import { BAlert } from 'bootstrap-vue';
import eventBus from './eventBus';
import DisableOverlay from "@/Components/DisableOverlay.vue";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import 'jquery/dist/jquery.min.js';
import 'popper.js/dist/umd/popper.min.js';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';


export default{
  name: 'app',
  components: {
    Header,// Newsletter,
    Footer, BAlert,
    DisableOverlay
  },
  
  data() {
    return {
      message: '',
      alertVariant: 'info', // Default variant
      user : false,
      showHideCookieBanner : true,
      isPageDisabled: false,
    };
  },
  created() {
    console.log("App Created ");
    
    this.userInfo = this.$store.getters.user;


    eventBus.$on('show-message', this.showMessage);
    eventBus.$on('close-message', this.closeMessage);
    eventBus.$on('show-cookie-banner', this.showCookieBanner);

    // Retrieve the last stored route from localStorage
    const lastRoute = this.$store.state.lastRoute;
    if (lastRoute && lastRoute.name !== 'ManageAccount') {
      this.currentRoute = lastRoute;
      // Use router.replace to avoid adding the route to history
      this.$router.replace(lastRoute);
    } else {
      this.$router.replace("/home");
    }

    // Listen for route changes and update the currentRoute
    this.$router.afterEach((to) => {
      this.currentRoute = to.path;
      // Store the current route in localStorage
      if(to.name !== 'ManageAccount') {
        this.$store.commit('setLastRoute', to.path);
      }
    });

    console.log("lastRoute : "+lastRoute);

  },
  computed: {
    isUserSigned() {
      return this.userInfo == null || Object.keys(this.userInfo).length === 0;
    }
  },
  methods: {
    showMessage(alertType,message) {
      console.log("message : "+message);
      this.alertVariant = alertType;        
      this.message = message;
      this.disablePage();
    },
    closeMessage() {
      console.log("closing message.");
      this.message = '';
      this.enablePage();
    },
    showCookieBanner(status) {
      console.log("closing message.");
      this.showHideCookieBanner = status;
    },
    async getUserInfo() {
      console.log("getting user info");
      try {
        this.loading = true;
        const response = await fetch(window.location.origin+'/userinfo');

        console.log("userInfo :  "+response.status);
        if(response.status == 401) {
          this.user = false;
          this.subStatus = null;
          this.$store.commit('clearAll');
         
        } else if(response.status == 200) {
          this.user = true;
          const responseData = await response.json();
          this.subStatus = responseData.subscription_status;
          this.userInfo = responseData;
          console.log("response data : "+JSON.stringify(responseData));
          this.$store.commit('setUser', responseData);
        }
       
      } catch (error) {
        console.log(error);
        this.alertMessage = 'System Error';
        this.alertColor = 'alert-danger';
        this.loading=true;
      }
    },
    async getUserDetails() {
      console.log("getUserDetails");
      try {
        this.loading = true;
        const response = await fetch(window.location.origin+'/user-details');

        console.log("userInfo :  "+response.status);
        if(response.status == 401) {
          this.user = false;
          this.$store.commit('clearAll');
         
        } else if(response.status == 200) {
          this.user = true;
          const responseData = await response.json();
          this.subStatus = responseData.subscription_status;
          this.userInfo = responseData;
          console.log("response data : "+JSON.stringify(responseData));
          this.$store.commit('setUser', responseData);
        }

       
      } catch (error) {
        console.log(error);
        this.alertMessage = 'System Error';
        this.alertColor = 'alert-danger';
        this.loading=true;
      }
    },
    acceptNecessaryCookies: function() {
      //let acceptedOk =confirm('Cookies accepted!');
      //if(acceptedOk) {
        this.$store.commit('setNecessaryCookiesAccepted', true);
        this.enablePage();
        this.hideBanner();
      //}
    },
    acceptAllCookies: function() {
      //let acceptedOk =confirm('Cookies accepted!');
      //if(acceptedOk) {
        this.$store.commit('setAllCookiesAccepted', true);
        this.enablePage();
        this.hideBanner();
      //}
    },
    declineCookies: function() {
      //let declinedOk = confirm('Cookies declined!');
      //if(declinedOk) {
        this.$store.commit('setCookiesDeclined');
        this.disablePage();
      //}
    },
    hideBanner: function() {
      // Function to hide the banner
      document.querySelector('.cookie-banner').style.display = 'none';
    },
    disablePage() {
      this.isPageDisabled = true;
    },
    enablePage() {
      this.isPageDisabled = false;
    }


  },
  
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Exo+2');
#app {
  background-color: #F8F8F8 !important;
  font-family: 'Exo 2', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.close{
}
</style>

<style scoped>
/* Add your CSS styling for the header here */
.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5; /* Adjust as needed */
  background-color: #ffffff; /* Adjust background color */
  /* Add any other CSS styles you need */
}
.message{
  position: absolute;
  top: 70px;
  left: 0px;
  width: 100%;
  z-index:999; /* Adjust as needed */
  //background-color: #ffffff; /* Adjust background color */
}
.footer{
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index:0; /* Adjust as needed */
  background-color: #ffffff; /* Adjust background color */
}
.fixed-header.loading {
  /* Apply styles to disable the container */
  pointer-events: none; /* Disable pointer events on the container */
  opacity: 0.5; /* Reduce opacity to indicate it's disabled */
}

.cookie-banner {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      color: #fff;
      padding: 10px;
      text-align: center;
      z-index:99999
    }

    .cookie-banner button {
      margin: 0 10px;
    }

/* Add the following CSS styles in your component's style section */
.text-style {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-size: 18px;
  line-height: 1.6;
  color: #333; /* Adjust color as needed */
}

    
</style>
